import { Link } from '@remix-run/react';

import logo from '../../images/logo-wide.svg';

interface BareLayoutProps {
  children: React.ReactNode;
}

export default function BareLayout({ children }: BareLayoutProps) {
  return (
    <div className="w-screen h-screen overflow-x-hidden flex flex-col p-8 sm:p-14">
      <header>
        <nav className="flex">
          <Link to="/">
            <img src={logo} alt="cardonomics logo" />
          </Link>
        </nav>
      </header>
      <main className="py-14 flex-col flex-grow">{children}</main>
    </div>
  );
}
