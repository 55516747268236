import { Outlet } from '@remix-run/react';
import BareLayout from '~/components/layout/BareLayout';

export default function AuthLayout() {
  return (
    <BareLayout>
      <Outlet />
    </BareLayout>
  );
}
